@use 'sass:map';
@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;
@use './libs/shared-wireframe/src/lib/app-wireframe/app-wireframe.theme' as appWireframe;

@import 'ngx-toastr/toastr';
@import 'node_modules/flag-icon-css/css/flag-icons.min.css';

@include mat.core();

$my-theme: mat.define-light-theme(
    (
        color: (
            primary: mat.define-palette(mat.$blue-palette),
            accent: mat.define-palette(mat.$pink-palette)
        ),
        typography: mat.define-typography-config()
    )
);

@include mat.all-component-themes($my-theme);
@include appWireframe.theme($my-theme);
@include mtx.all-component-themes($my-theme);

.page {
    padding: 8px;
}

.page > .page-title {
    margin-top: 0;
    padding: 0 0 4px 8px;
}

.page .page-title {
    margin-bottom: 10px;
}

.page .page-search {
    margin-bottom: 10px;
}

mat-card-title h2,
mat-card-title h3,
mat-card-subtitle h2,
mat-card-subtitle h3 {
    padding: 0 0 4px 0 !important;
}

mat-form-field {
    width: 100%;
}

mat-card-title,
mat-card-subtitle {
    h2,
    h3 {
        margin: 0 0 0.5em 0;
    }
}

mat-card {
    margin: 6px;

    mat-card-actions {
        gap: 8px;
    }
}

mat-dialog-actions {
    gap: 8px;
}

h1 {
    font-size: xx-large;
    padding-left: 8px;
    margin: 0;
}

h2 {
    font-size: larger;
}

h3 {
    font-size: large;
}

.flex-column {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.flex-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    column-gap: 4px;
}

form {
    padding: 0;
}

/* Media queries */
@media (max-width: 800px) {
    .flex-row {
        flex-direction: column;
    }
}

/* Added to style amplify authenticator box */
amplify-authenticator {
    div[data-amplify-authenticator] {
        div[data-amplify-router] {
            amplify-slot[name='sign-in'] > .sign-in-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;

                .sign-in-form {
                    position: absolute;
                    top: 80px;
                    right: 2%;
                    width: 31%;

                    h1 {
                        margin-bottom: 16px;
                    }
                }

                .sign-in-background {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 65%;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    overflow: hidden;
                }
            }
        }
    }
}

/* Added to make disabled formfields readable */
.mat-form-field-disabled {
    .mat-mdc-select-value-text,
    .mat-mdc-input-element {
        color: rgba(0, 0, 0, 0.75);
    }

    .mdc-floating-label {
        color: rgba(0, 0, 0, 0.75);
    }
}

.mdc-dialog__container .mdc-dialog__title + .mdc-dialog__content {
    // When a form field is displayed as the first element in a dialog, it's floating label
    // gets wrapped by the dialog content container. Adding this padding will ensure that
    // the label is fully visible.
    padding-top: 8px;
}

.form-actions,
.button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 0 6px 6px 6px;
    gap: 10px;
}

mat-card-content .button-bar {
    padding: 0 !important;
}

.list-icon,
.mat-mdc-form-field .mat-icon {
    color: rgba(100, 100, 100, 0.6);
}

.remaining-hours-background-red:not(linkx-list-item),
linkx-list-item.remaining-hours-background-red > div.list-item {
    background: rgba(244, 67, 54, 0.1);
}

.remaining-hours-background-orange:not(linkx-list-item),
linkx-list-item.remaining-hours-background-orange > div.list-item {
    background: rgba(255, 152, 0, 0.1);
}

.remaining-hours-background-green:not(linkx-list-item),
linkx-list-item.remaining-hours-background-green > div.list-item {
    background: rgba(76, 175, 80, 0.1);
}

@media screen and (max-width: 800px) {
    amplify-authenticator {
        div[data-amplify-authenticator] {
            div[data-amplify-router] {
                amplify-slot[name='sign-in'] > .sign-in-wrapper {
                    .sign-in-form {
                        width: calc(80% - 64px);
                        left: 10%;
                        right: 10%;
                        padding: 32px;
                        border-radius: 32px;
                        background: rgba(255, 255, 255, 0.8);
                        z-index: 1;
                    }

                    .sign-in-background {
                        width: 100%;
                        left: 0;
                        z-index: 0;
                    }
                }
            }
        }
    }
}
